import React, { useState } from "react";
import telephone from "../../src/assets/telephone.png";
import hold from "../../src/assets/holdicon.png";
 import mute from "../../src/assets/muteicon.png";
 import endcall from "../../src/assets/endcall.png";
import { appColors } from "../local/commonscript";
import InitialCCPPage from "./initialCCP";

const OngoingCall = ({onEndCallBtn,onHoldBtn}) => {
  const [status, setStatus] = useState("available");
  // const [endCall, setEndCall] = useState(false);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
// const onEndCall = () => {
//   console.log('End Call');
//   setEndCall(true)

// }
// if(endCall){
//   return <InitialCCPPage />
// }
  return (
      <div style={{height: '95vh', display:'flex',flex:1,flexDirection:'column'}}>
<div style={{display: "flex", justifyContent: "space-between", backgroundColor: appColors.secondaryBlue}}>
      <div
        style={{
          height: "40px",
          color: "white",
          fontSize: "18px",
          display: "flex",
          paddingTop: "4px",
          paddingLeft: "10px",
        }}
      >
        080-11-22-33-44
      </div>
      <div style={{
        color: "white",
        fontSize: "12px",
        display: "flex",   
        marginTop: "20px",
      }}>
Ongoing Call
      </div>
      </div>
<div style={{display: 'flex',flex:1,alignItems:'end',justifyContent:'center',justifyItems:'center',flexDirection:'row'}}>
    
    
    <button name="hold" onClick={onHoldBtn} style={{backgroundColor: 'white',padding:5,width: "25%", display:'flex',flexDirection:'row',justifyContent:'center'}} >
    <img src={hold}/>
      Hold</button>

    
      <button name="mute" style={{backgroundColor: 'white',padding:5, marginLeft:20,width: "25%", display:'flex',flexDirection:'row',justifyContent:'center'}} >
    <img src={mute}/>
      Mute
      
      </button>
</div>
<div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'end',paddingBottom:20}}>

    <button name="Endcall" style={{ backgroundColor: 'white',width: "35%", height: "30px", fontSize: "16px" , }} onClick={onEndCallBtn} >
    <img style={{marginRight: "4px"}} src={endcall}/>
    End Call </button>
</div>
      </div>
  );
};
export default OngoingCall;
