import "amazon-connect-streams";
import React, { useEffect, useState } from "react";
import mdnok from "../../src/assets/mdnok.svg";
import pinNoteok from "../../src/assets/pinNotok.svg";
import { genLogger, valueToOption } from "../lib";

import { keyNameMappings, appColors } from "../local/commonscript";
import { stylesEle } from "./commonStyle";
import {
  renderScript,
  dummyIVRdata,
  dummyvoiceBotdata,
  dummyvoiceBotdataAllOk,
  renderTalkScript,
  dummyShopdata,
} from "../local/commonfunction";
import VoiceBotDataComponent from "./VoiceBotData";
import { FullScreenCircularProgress } from "./FullScreenCircularProgress";

import irisNavigation from "../../src/assets/irisNavigation.svg";
import threedot from "../../src/assets/threedot.svg";
import phonecall from "../../src/assets/phonecall.svg";import CallFromShopComponent from "./CallFromShopComponent";
import { Container } from "@uap/uap-ui-components";
import OutBoundDeatils from "./OutBoundDetails";
import { use } from "react";

const name = "CustomerInfo";
const { log, error } = genLogger(name);
const Abc = (props) => {
  const [agentStatus, setAgentStatus] = useState();

  const [route, setRoute] = useState("-");
  const [isLoading, setisLoading] = useState(false);
  const [, setisAlert] = useState(false);
  const [bgColor, setBgColor] = useState(true);
  const [phonecallClicked, setPhoneCallClicked] = useState(true);
  const [threedotButtonClicked, setThreedotButtonClicked] = useState(false);
  const [irisnavigationclicked, setirisnavigationclicked] = useState(false);
  const [, setOnclickyes] = useState(false);
  const [agentName, setAgentName] = useState("-");
  const [agentextid, setagentextid] = useState();
  const [callfromShopData, setCallFromShop] = useState();
  const [getShopData, setShopData] = useState(false);
  const [getReservationData, setReservationData] = useState();
  const [getReservationSlotData, setReservationSlotData] = useState();
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedThemeColor, setSelectedThemeColor] = useState(
    appColors.primaryGrey
  );
  const [data, setData] = useState({
    mdn: "-",
    subscribername: "-",
    contractmdn: "-",
    Queue: "-",
    mdnok: "-",
    pinok: "-",
    route: "-",
    botMDN: "-",
    voicebotName: "-",
    voicebotContractor: "-",
    DOB: "-",
    IsVoiceBotCalled: "",
    ExtenionID: "32001",
    CallingNumber: "-",
    flowName: "-",
    VoiceBotEmergencyQueueEnabled: "",
    ShouldTransferToVoiceBot: "",
    IsUapEmergencyQueueEnabled: "",
  });
  const [agentData] = useState(
    JSON.parse(sessionStorage.getItem("agentData")) || ""
  );

  const renderImgValue = (ele) => {
    const { lable, value } = ele;

    const renderImg = ["MDN", "PIN "].includes(lable);

    if (renderImg) {
      if (
        (data?.mdnok === "true" && lable === "MDN") ||
        (data?.pinok === "true" && lable === "PIN ")
      ) {
        return <img src={mdnok} alt="mdnOk" />;
      } else if (
        (data?.mdnok === "false" && lable === "MDN") ||
        (data?.pinok === "false" && lable === "PIN ") ||
        data?.flowName === "CsAutoTransfer"
      ) {
        return <img src={pinNoteok} alt="pinNoteOk" />;
      } else {
        return "-";
      }
    } else if (lable === "転送用ID") {
      return <div style={{ ...stylesEle.customerContainerInside }}>320001</div>;
    } else if (lable === "業務") {
      return (
        <div style={{ ...stylesEle.customerContainerInside }}>スキル5</div>
      );
    } else {
      return (
        <div style={stylesEle.customerContainerInside}>
          {value ? value : "-"}
        </div>
      );
    }
  };

  const displaySubcriberData = (key) => {
    return !data?.IsVoiceBotCalled ? (
      data[key]
    ) : data?.botMDN === "Matched" && data?.voicebotName === "Matched" ? (
      data[key]
    ) : (
      <div style={{ marginLeft: "6px" }}>-</div>
    );
  };
  const displayMDNData = (key) => {
    if (data.IsVoiceBotCalled) {
      return !data.botMDN || data.botMDN !== "Matched" ? (
        <div style={{ marginLeft: "6px" }}>-</div>
      ) : (
        data[key]
      );
    } else if (data?.mdnok === "true" && !data?.IsVoiceBotCalled) {
      return data[key];
    } else {
      return <div>-</div>;
    }
  };

  const fetchValue = (key, data) => {
    switch (key) {
      case "subscribername":
        return displaySubcriberData(key);
      case "contractmdn":
        return displayMDNData(key);
      default:
        return data[key];
    }
  };
  const formatKeyAndValue = () => {
    let dataRes = [];

    for (let key in keyNameMappings) {
      const values = fetchValue(key, data);
      let obj = {
        lable: keyNameMappings[key],
        value: values,
      };
      dataRes.push(obj);
    }
    return (
      <div style={stylesEle.customerHeader}>
        {dataRes.map((ele, id) => {
          return (
            <>
              <div style={stylesEle.customerHeaderInside}>
                <div style={stylesEle.customerContainer}>
                  {ele.img}
                  <div style={stylesEle.styleLable}>{ele.lable}</div>
                </div>
                <div style={stylesEle.customerContainerInside}>
                  {renderImgValue(ele)}
                </div>
              </div>
              {dataRes.length - 1 > id && (
                <div style={stylesEle.customerContainerBody}></div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const handleDummyCall = (arg) => {
    setSelectedButton(arg);
    props.handleOnCallBtn(arg);

    if (arg == "Button1") {
      setData(dummyIVRdata);
    } else if (arg == "Button2") {
      setData(dummyvoiceBotdataAllOk);
    } else if (arg == "Button3") {
      setData(dummyvoiceBotdata);
    } else if (arg == "Button4") {
      setData(dummyShopdata);
    }
     else {
      setData("");
    }
  };
  const handleVoiceBotResult = () => {
    if (
      data?.IsVoiceBotCalled === "true" ||
      data?.VoiceBotEmergencyQueueEnabled === "true" ||
      data?.ShouldTransferToVoiceBot === "true" ||
      data?.IsUapEmergencyQueueEnabled === "true"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleFlowName = () => {
    if (
      data?.flowName === "Hankyo" ||
      data?.flowName === "HP" ||
      data?.flowName === "CsAutoTransfer"
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setSelectedButton(props.selectedActions);
  }, [props.selectedActions]);
  const fetchThemeolor = (selectedAction) => {
console.log("selectedActionselectedAction", selectedAction)
    switch (selectedAction) {
      case "Button1":
      case "Button2":
      case "Button3":
      case "Button4" :
        return appColors.primaryGreen;
      case "Acceptcall":
        return appColors.secondaryBlue; //call accepted
      case "Rejectcall":
        handleDummyCall('Rejectcall');
        return appColors.primaryBlue; //call rejected
      case "Endcall":
        handleDummyCall('Endcall');
        return appColors.primaryBlue; //availble status
        case "available":
          handleDummyCall('available');
          return appColors.primaryBlue; 
      default:
        handleDummyCall('offline');
        return appColors.primaryGrey;
    }
  };
  useEffect(() => {
    const color = fetchThemeolor(selectedButton);
    setSelectedThemeColor(color);
  }, [selectedButton]);
  useEffect(() => {
    setShopData(selectedButton === "Button4");
    const obj = {
      ClientStoreAttribute: {
        BUSINESSNAMEKANA: "auｼｮｯﾌﾟ ｵｵﾏﾁ",
        StoreCode: "TR01003",
      },
      Name: "auｼｮｯﾌﾟおおまち",
    };
    setCallFromShop(obj);
  }, [selectedButton, agentStatus]);
  return (
    <>
      {isLoading && <FullScreenCircularProgress />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#454343",
        }}
      >
        <div
          style={{
            height: "38px",
            backgroundColor: "#454343",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          <div style={{}}>
            <button
              style={{
                height: "38px",
                backgroundColor: "#454343",
                border: "none",
              }}
            >
              <img src={phonecall}></img>
            </button>
            <button
              style={{
                height: "38px",
                backgroundColor: "#454343",
                border: "none",
              }}
            >
              <img src={threedot}></img>
            </button>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#454343",
                border: "none",
                height: "38px",
              }}
            >
              <img src={irisNavigation} alt="mdnOk" />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          ...stylesEle.container,
          backgroundColor: selectedThemeColor?.toString(),
        }}
      >
        {phonecallClicked && (
          <>
            <div style={stylesEle.headercontainer}>{formatKeyAndValue()}</div>
            {/* data from voice bot */}
            <Container scroll={true} style={{ maxHeight: "500px" }}>
              <div
                className="scrollbar"
                style={{
                  overflowY: "auto",
                  maxHeight: "500px",
                }}
              >
                {handleVoiceBotResult() && handleFlowName() && (
                  <VoiceBotDataComponent
                    data={{
                      MDN: data.botMDN,
                      Contractor: data.voicebotContractor,
                      Name: data.voicebotName,
                      DOB: data.DOB,
                      IsVoiceBotCalled: data?.IsVoiceBotCalled,
                    }}
                  />
                )}
                {getShopData && (
                  <CallFromShopComponent shopData={callfromShopData} />
                )}
                <div style={stylesEle.alertContainer}>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {renderTalkScript(
                      data,
                      callfromShopData?.ClientStoreAttribute?.BUSINESSNAMEKANA,
                      callfromShopData?.ClientStoreAttribute?.StoreCode
                    )}
                  </div>
                </div>
                <OutBoundDeatils
                  reservationData={getReservationData}
                  reservationSlotData={getReservationSlotData}
                />
              </div>
            </Container>
            {/* <TargetQuickConnect /> */}
          </>
        )}

        <div style={stylesEle.alertContainer}>
          <div style={{ whiteSpace: "pre-wrap" }}>{renderScript(data)}</div>
        </div>
        <button
          name="Button1"
          onClick={() => {
            handleDummyCall("Button1");
            // setSelectedButton("Button1");
          }}
          style={{ marginLeft: "10px" }}
        >
          1
        </button>

        <button
          name="Button2"
          onClick={() => {
            handleDummyCall("Button2");
            // setSelectedButton("Button2");
          }}
          style={{ marginLeft: "10px" }}
        >
          2
        </button>
        <button
          name="Button3"
          onClick={() => {
            handleDummyCall("Button3");
            // setSelectedButton("Button3");
          }}
          style={{ marginLeft: "10px" }}
        >
          3
        </button>
        <button
          name="Button4"
          onClick={() => {
            handleDummyCall("Button4");
            // setSelectedButton("Button3");
          }}
          style={{ marginLeft: "10px" }}
        >
          4
        </button>
      </div>
    </>
  );
};

export default Abc;
