export const stylesEle = {
  renderdiv: {
    padding: "10px",
    fontSize: "18px",
    borderBottom: "2px solid grey",
  },
  container: {
    flex: 1,
    border: "none",
    minHeight: "92vh",
     paddingBottom: "20px",
  },
  headerdiv: {
    backgroundColor: "#4a4a4a",
    padding: "9px",
    color: "white",
    fontSize: "18px",
    flex: 1,
    width: "100%",
    marginLeft: "2px",
  },
  headercontainer: {
    display: "flex",
    flex: 1,
    padding: "16px"
  },
  renderLable: {
    flex: 0.5,
    justifyContent: "flex-start",
    fontSize: "30px",
    borderBottom: "2px solid grey",
  },
  renderValue: {
    flex: 0.5,
    justifyContent: "flex-start",
    fontSize: "30px",
    borderBottom: "2px solid grey",
  },
  alertheader: {
    backgroundColor: "blue",
    textAlign: "center",
    padding: "10px",
    color: "white",
    fontSize: "18px",
    marginTop: "25px",
  },
  alertContainer: {
    fontSize: "18px",
    color: "white",
     paddingLeft: "16px",
    flex: 1,
  },
  customerHeader: {
    diplay: "flex",
    flexDirection: "row",
    flex: 1,
  },
  customerHeaderInside: {
    display: "flex",
    flex: 1,
  },
  customerContainer: {
    flex: 0.4,
    alignContent: "center",
    fontSize: "13px",
    display: "flex",
    flexDirection: "row",
    color: "white",
  },
  customerContainerInside: {
    flex: 0.6,
    fontSize: "18px",
    color: "white",
  },
  customerContainerBody: {
    height: "2px",
    backgroundColor: "#f0ede6",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.16)",
  },
  contentDialog: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "20%",
    width: "40%",
  },
  styleLable: {
    alignSelf: "center",
  },
  styleDialog: {
    width: "35%",
    height: "35%",
    position: "absolute",
    top: "20%",
    border: "1px  grey",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px",
  },
  stylesButtonBox: {
    flex: 1,
    display: "flex",
    justifyContent: "space-evenly",
    flexShrink: "inherit",
    marginTop: "10px",
    color: "white",
  },
  stylesButtonBody: {
    display: "flex",
    flex: 0.4,
    padding: 10,
    justifyContent: "space-between",
    backgroundColor: "#00000038",
    border: "1px solid gray",
    fontSize: "14px",
  },
  stylescrollbar: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "16px",
      height: "242px",
      gap: "0px",
      border: "1px 0px 0px 0px",
      opacity: "0px",
      background: "#F2F2F2",
      border: "1px solid #E4E4E4",
    },
    "&::-webkit-scrollbar-thumb": {
      width: "10px",
      height: "40px",
      top: "3px",
      gap: "10px",
      opacity: "0px",
      background: "rgba(0, 0, 0, 0.22)",
      borderRadius: "20px",
      border: "3px solid #F2F2F2",
      minHeight: "15px",
    },
  },
};
