import React, { useCallback, useState, useEffect } from "react";
import ConnectCCP from "./ConnectCCP";

import { configEnv } from "../config/config";

import Abc from "./abc";
import InitialCCPPage from "./initialCCP";
import IncommingCall from "./incomingCall";
import OngoingCall from "./ongoingCall";
import NewAgentInfo from "./NewAgentInfo";

const styleEle = {
  renderHeader: {
    display: "flex",
    flex: 1,
  },
  renderBody: {
    width: "calc(100% - 268px)",
    float: "left",
    backgroundColor: "#F2F2F2",
  },
  renderAgentInfo: {
    flex: 1,
    backgroundColor: "#F2F2F2",
  },
  renderCustomerBody: {
    width: "268px",
    float: "left",
    backgroundColor: "#F2F2F2",
  },
  renderSeatMapInfo: {
    display: "flex",
    flex: 1,
    backgroundColor: "#F2F2F2",
    width: "100%",
    height: "100%",
  },
  headerdiv: {
    backgroundColor: "#4a4a4a",
    height: "40px",
    color: "white",
    fontSize: "14px",
    flex: 1,
    width: "100%",
  },
  customerinfodiv: {
    height: "88%",
    border: "none ",
  },
};
const Dashboard = () => {
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [, setAgentData] = useState({});
  const [dummyCallSection, setDummyCallSection] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);
  const [ongoingCall, setOngoingCall] = useState(false);
  const [callRejected, setCallRejected] = useState(false);
  const [, setlogoutdata] = useState(true);
  const [selectedBtn, setSelectedBtn] = useState('offline');

  // Avoid re-rendering ConnectCCP

  const CCP = useCallback(
    () => (
      <ConnectCCP
        islogout={(arg) => {
          setlogoutdata(arg);
          setShowCustomerInfo(false);
          window.location.reload();
        }}
      />
    ),
    []
  );

  const handleOnAgentOk = (data) => {
    setAgentData(data);
    localStorage.setItem("agentData", JSON.stringify(data));
    setShowCustomerInfo(true);
  };
  const env = configEnv?.authConfig?.clientId;

  let environment = "";
  switch (env) {
    case "UAPAcyanSqa1":
      environment = "SQA1";
      break;
    case "UAPAcyanUat":
      environment = "UAT";
      break;
    case "UAPAcyanTrn":
      environment = "TRN";
      break;
    default:
      environment = "";
  }
  const displayDummyCallSection = (arg) => {
    setDummyCallSection(true);
    setIncomingCall(true);
    setSelectedBtn(arg);
    setOngoingCall(false);
  };

  const launchIris = (url) => {
    url =
      "https://acyan-uat.uap.jpnmob-acyan.npr.aws.asurion.net/replacement?client=au&UCID=07960c4f-5145-4026-95c7-bc86fe09ad97&VDN=555110&application=horizon&skill=%E3%80%90%E6%95%85%E3%80%91SHOP&position=LD";

    window.open(url);
  };
  const launchIrisShop = (url) => {
    url =
      "https://acyan-uat.uap.jpnmob-acyan.npr.aws.asurion.net/replacement?client=au&UCID=07960c4f-5145-4026-95c7-bc86fe09ad97&VDN=555110&application=horizon&skill=%E3%80%90%E6%95%85%E3%80%91SHOP&position=LD&shopID=TR01003";

    window.open(url);
  };

  const renderComponent = (selectedButton) => {
    switch (selectedButton) {
      case 'Button1':
      case 'Button2':
      case 'Button3':
        return (
          <IncommingCall
            onAccept={() => {
              setOngoingCall(true);
              setSelectedBtn('Acceptcall');
              launchIris();
            }}
            onReject={() => setSelectedBtn('Rejectcall')}
          />
        );
        case 'Button4':
        return (
          <IncommingCall
            onAccept={() => {
              setOngoingCall(true);
              setSelectedBtn('Acceptcall');
              launchIrisShop();
            }}
            onReject={() => setSelectedBtn('Rejectcall')}
          />
        );
      case 5:
        return <InitialCCPPage />;
      default:
        return <InitialCCPPage />;
    }
  };

  const [status, setStatus] = useState("");
  const handleStatusChange = (event) => {
    console.log({ event });
    setStatus(event.target.value);
    setSelectedBtn(event.target.value);
    console.log("selectedBtnnnnnnnn", selectedBtn, status );
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          height: "40px",
          backgroundColor: "black",
          color: "white",
          fontSize: "18px",
          display: "flex",
        }}
      >
        <select
          style={{
            height: "40px",
            backgroundColor: "black",
            color: "white",
            width: "50%",
            paddingLeft: "10px",
          }}
          id="status"
          value={status}
          onChange={handleStatusChange}
        >
          {/* {agentStatus.map((status) => (
            <option key={status.description} value={status.description}>
              {status.name}
            </option>
          ))} */}
         
          <option id="offline" value="offline">オフライン</option>
          <option value="available">受付可</option>
          <option value="away">離席中</option>
        </select>
      </div>
    );
  };

  // const fetchAction = () => {
  //   if (ongoingCall) {
  //     return "Acceptcall";
  //   }else if (status === "available") {
  //     return "available";
  //   } else {
  //     return selectedBtn;
  //   }
  // };

  return (
    <>
      {environment ? (
        <div
          style={{
            textAlign: "center",
            color: "white",
            background:
              "linear-gradient(45deg, violet, indigo, blue, green, yellow, orange, red)",
          }}
        >
          This is the {environment} environment!
        </div>
      ) : null}
      <div style={{ flex: 1 }}>
        <div style={styleEle.renderHeader}>
          {!showCustomerInfo ? (
            <div style={styleEle.renderAgentInfo}>
              <NewAgentInfo handleOk={(data) => handleOnAgentOk(data)} />
              <div>diksha</div>
            </div>
          ) : (
            <div style={{ flex: 1, display: "flex", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  height: "99vh",
                  width: "calc(100% - 268px)",
                  backgroundColor: "#F2F2F2",
                }}
              >
                {renderHeader()}
                {ongoingCall ? <OngoingCall onEndCallBtn={()=>{setOngoingCall(false); setSelectedBtn('Endcall')}} onHold={()=>setSelectedBtn('Hold')} /> : renderComponent(selectedBtn)}
                {/* <img
                    src={incomingCall ? incommingCall : callOngoing}
                    alt="mdnOk"
                    style={{ height: "99vh", width: "100%" }}
                    onClick={() => setIncomingCall(false)}
                  /> */}
              </div>

              <div style={styleEle.renderBody}>
                <InitialCCPPage />
                {/* <OngoingCall /> */}
                {/* <IncommingCall /> */}
              </div>
              <div style={styleEle.renderCustomerBody}>
                <div>
                  <Abc
                    handleOnCallBtn={displayDummyCallSection}
                    selectedActions={selectedBtn}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
