
import React, { useCallback, useMemo, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";

const Application = () => {
 
  // const Dash = useMemo(() => operationSupportFeatureFlagOn ? withLayout(DashboardPage) : withLayout(Dashboard), []);
  const Dash = useMemo(() => (Dashboard), []);

  
  // const onRefresh = (tokens) => {
  //   if (tokens.accessToken) {
  //     authenticated(undefined, tokens, false);
  //     setsession(tokens);
  //     setfirstTime(false);
  //   }
  // };
  return (
    <>
        <Router>
          <Route exact path="/" component={Dash} />
        </Router>
    </>
  );
};

export default Application;
