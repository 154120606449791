export const authEnv = (env) => ({
  clientId: env,
  authorizationUrl: "https://ndcsso.asurion.com:9031/as/authorization.oauth2",
  tokenUrl: "https://ndcsso.asurion.com:9031/as/token.oauth2",
});

export const authConfig = {
  SQA: authEnv("UAPAcyanSqa1"),
  UAT: authEnv("UAPAcyanUat"),
  TRN: authEnv("UAPAcyanTrn"),
  STG: authEnv("UAPAcyanPrd"),
  PRD: authEnv("UAPAcyanPrd"),
};

export const getclientChannelId = {
  AU: "7D42D83E285511E9A38A063D3002FD4C",
};
export const config = {
  local: {
    domainName: "asurion-uap-acyan-sqa-pool-jpnmob.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.SQA,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
  sqa: {
    domainName: "asurion-uap-acyan-sqa-pool-jpnmob.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.SQA,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
  uat: {
    domainName: "asurion-uap-acyan-uat-pool.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.UAT,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
  trn: {
    domainName: "asurion-uap-acyan-trn-pool.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.TRN,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
  stg: {
    domainName: "asurion-uap-acyan-prd-pool.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.STG,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
  prd: {
    domainName: "asurion-uap-acyan-prd-pool.auth.ap-northeast-1.amazoncognito.com",
    region: "ap-northeast-1",
    authConfig: authConfig.PRD,
    allowManualSignin: true,
    signOutVisible: true,
    mandatorySignIn: false,
    providerId: "asurionsso",
    operationSupportFeatureFlagOn: true,
  },
};
