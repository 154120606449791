export const common = {
  OKOKcase:
    "[ContractName] 様\nここからは担当●●が承ります。いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか",
  OKNGcase:
    "お待たせいたしました。ここからは担当●●が承ります。\nご契約者様のお名前をフルネームで教えていただけますでしょうか\n電話口のお客さまは契約者ご本人様でしょうか\n※入電者が契約者ではない場合※\n【電話口のお客さまとご契約者様とのご関係を教えていただけますか\nこの端末は普段お電話口のお客さまがお使いになっていますか\n本日のお問合せ内容はご契約者様もご存じでしょうか】\n〇〇様、いつもご利用いただきありがとうございます。本日はいかがなさいましたか。",
  VoiceBotMDNOK:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約者様のお名前をフルネームで教えていただけますでしょうか\n電話口のお客さまは契約者ご本人様でしょうか\n※入電者が契約者ではない場合※\n【電話口のお客さまとご契約者様とのご関係を教えていただけますか\nこの端末は普段お電話口のお客さまがお使いになっていますか\n本日のお問合せ内容はご契約者様もご存じでしょうか】\n〇〇様、いつもご利用いただきありがとうございます。本日はいかがなさいましたか。",
  VoiceBotContractorOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約者様のお名前をフルネームで教えていただけますでしょうか\n〇〇様、いつもご利用いただきありがとうございます。本日はいかがなさいましたか。",
  VoiceBotNameOk:
    "[ContractName] 様\nガイダンスの操作にご協力いただきありがとうございます\nここからは担当●●が承ります。いつもご利用いただきありがとうございます。本日はいかがなさいましたか",
  VoiceBotAllOk:
    "[ContractName] 様\nガイダンスの操作にご協力いただきありがとうございます\nここからは担当●●が承ります。いつもご利用いただきありがとうございます。本日はいかがなさいましたか",
  SolutoWAHAllOk:
    "お待たせいたしました。担当●●でございます。\n失礼ですが電話口のお客さまは[ContractName]（フルネーム）様、ご本人様でしょうか\n\n【対話者が契約者ではない場合】\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか\n・この端末は普段電話口のお客さまがお使いになっていますか\n・本日のお問合せ内容はご契約者様もご存じでしょうか\n\n〇〇様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  WAHAllOk:
    "お待たせいたしました。担当●●でございます。\n失礼ですが電話口のお客さまは[ContractName]（フルネーム）様、ご本人様でしょうか\n\n【対話者が契約者ではない場合】\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか\n・この端末は普段電話口のお客さまがお使いになっていますか\n・本日のお問合せ内容はご契約者様もご存じでしょうか\n\n〇〇様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  WAHOKNG:
    "お待たせいたしました。担当●●でございます。\nご契約のお電話番号はご入力いただいた××××でよろしいでしょうか。\nありがとうございます。続きまして2点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n\n【契約者本人の場合】\n（対応を進める）〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n【契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
  WAHVoiceBotMDNOK:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。続きまして2点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか。\n重ねての質問で恐縮ですが（たびたびお伺いして申し訳ございませんが）、電話口のお客さまは契約者ご本人様でしょうか。\n\n【対話者が契約者本人の場合】\n〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n【対話者が契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【対話者が第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
  WAHVoiceBotContractorOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。\n恐れ入りますが、今一度ご契約者様のお名前をフルネームで教えていただけますでしょうか。\n \n○○様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  WAHVoiceBotNameOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。\n\n[ContractName]（フルネーム）様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  WAHVoiceBotAllOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\n\n[ContractName]（フルネーム）様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  CCAllOk:
    "お待たせいたしました。担当●●でございます。\n\n失礼ですが、電話口のお客さまは[ContractName]（契約者名フルネーム）様ご本人様でしょうか。\n\n【対話者が契約者本人の場合】\n（対応を進める）〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n 【対話者が契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【対話者が第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
  CCOKNG:
    "お待たせいたしました。担当●●でございます。\nご契約のお電話番号はご入力いただいた××××でよろしいでしょうか。\nありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n最後に恐れいりますが、ご契約者様の生年月日をお願いします。\n\n【対話者が契約者本人の場合】\n（対応を進める）〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n【対話者が契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【対話者が第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
  CCVoiceBotMDNOK:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか。\n重ねての質問で恐縮ですが（たびたびお伺いして申し訳ございませんが）、電話口のお客さまは契約者ご本人様でしょうか。\n最後に恐れいりますが、ご契約者様の生年月日をお願いします。\n\n【対話者が契約者本人の場合】\n〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n【対話者が契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【対話者が第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
  CCVoiceBotContractorOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。続きまして2点確認させてください。\n恐れ入りますが、今一度ご契約者様のお名前をフルネームで教えていただけますでしょうか。\n最後に、ご契約者様の生年月日をお願いします。\n \n○○様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  CCVoiceBotNameOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\nご契約のお電話番号はお伝えいただいた××××でよろしいでしょうか。ありがとうございます。\n恐れいりますが、今一度ご契約者様の生年月日をお伺いできますか。\n\n[ContractName]（フルネーム）様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  CCVoiceBotAllOk:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\n\n[ContractName]（フルネーム）様、いつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。",
  CCSolutoShop:
    "[ShopName]様、お待たせいたしました。担当●●でございます。\nご担当者様のお名前をお願い致します。\nお客さまはご来店中でしょうか。\n\n【来店中の場合】\nお客さまのご本人確認はお済みでしょうか。\n\n①本人確認済の場合（電話口：ショップ担当者）\n→ありがとうございます。続きまして4点確認させてください。\nまずはご契約のお電話番号をお願いします。\nご契約者様のお名前をフルネームでお願いします。\n本人確認は暗証番号で実施されましたか？（暗証番号/生年月日/住所）\nご来店者様はご契約者様ご本人様でよろしいでしょうか？\n\n②本人確認未実施の場合\n→かしこまりました。では私からお客さまに直接確認させていただきます。\nお電話をかわっていただくことは可能でしょうか？\n\n（電話口：お客さま）\nお待たせいたしました。担当●●でございます。\nご契約のお電話番号を教えていただけますでしょうか。\n\nありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n最後に恐れいりますが、ご契約者様の生年月日をお願いします。\n\n【来店中ではない場合】\n申し訳ございませんが、お客さまがいらっしゃる状態でないと受付ができかねますので、お客さまからおかけなおしをお願いいたします。",
  WAHSolutoShop:
    "[ShopName]様、お待たせいたしました。担当●●でございます。\nご担当者様のお名前をお願い致します。\nお客さまはご来店中でしょうか。\n\n【来店中の場合】\nお客さまのご本人確認はお済みでしょうか。\n\n①本人確認済の場合（電話口：ショップ担当者）\n→ありがとうございます。続きまして4点確認させてください。\nまずはご契約のお電話番号をお願いします。\nご契約者様のお名前をフルネームでお願いします。\n本人確認は暗証番号で実施されましたか？（暗証番号/生年月日/住所）\nご来店者様はご契約者様ご本人様でよろしいでしょうか？\n\n②本人確認未実施の場合\n→かしこまりました。では私からお客さまに直接確認させていただきます。\nお電話をかわっていただくことは可能でしょうか？\n\n（電話口：お客さま）\nお待たせいたしました。担当●●でございます。\nご契約のお電話番号を教えていただけますでしょうか。\n\nありがとうございます。続きまして2点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n\n【来店中ではない場合】\n申し訳ございませんが、お客さまがいらっしゃる状態でないと受付ができかねますので、お客さまからおかけなおしをお願いいたします。",
  CCNotRegSolutoShop:
    "お待たせいたしました。担当●●でございます。\n代理店コードからお願いいたします。\n\n（ショップ名）様でございますね。\nご担当者様のお名前をお願い致します。\n\nお客さまはご来店中でしょうか。\n\n【来店中の場合】\nお客さまのご本人確認はお済みでしょうか。\n\n①本人確認済の場合（電話口：ショップ担当者）\n→ありがとうございます。続きまして4点確認させてください。\nまずはご契約のお電話番号をお願いします。\nご契約者様のお名前をフルネームでお願いします。\n本人確認は暗証番号で実施されましたか？（暗証番号/生年月日/住所）\nご来店者様はご契約者様ご本人様でよろしいでしょうか？\n\n②本人確認未実施の場合\n→かしこまりました。では私からお客さまに直接確認させていただきます。\nお電話をかわっていただくことは可能でしょうか？\n\n（電話口：お客さま）\nお待たせいたしました。担当●●でございます。\nご契約のお電話番号を教えていただけますでしょうか。\n\nありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n最後に恐れいりますが、ご契約者様の生年月日をお願いします。\n\n【来店中ではない場合】\n申し訳ございませんが、お客さまがいらっしゃる状態でないと受付ができかねますので、お客さまからおかけなおしをお願いいたします。",
  WAHNotRegSolutoShop:
    "お待たせいたしました。担当●●でございます。\n代理店コードからお願いいたします。\n\n（ショップ名）様でございますね。\nご担当者様のお名前をお願い致します。\n\nお客さまはご来店中でしょうか。\n\n【来店中の場合】\nお客さまのご本人確認はお済みでしょうか。\n\n①本人確認済の場合（電話口：ショップ担当者）\n→ありがとうございます。続きまして4点確認させてください。\nまずはご契約のお電話番号をお願いします。\nご契約者様のお名前をフルネームでお願いします。\n本人確認は暗証番号で実施されましたか？（暗証番号/生年月日/住所）\nご来店者様はご契約者様ご本人様でよろしいでしょうか？\n\n②本人確認未実施の場合\n→かしこまりました。では私からお客さまに直接確認させていただきます。\nお電話をかわっていただくことは可能でしょうか？\n\n（電話口：お客さま）\nお待たせいたしました。担当●●でございます。\nご契約のお電話番号を教えていただけますでしょうか。\n\nありがとうございます。続きまして2点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n\n【来店中ではない場合】\n申し訳ございませんが、お客さまがいらっしゃる状態でないと受付ができかねますので、お客さまからおかけなおしをお願いいたします。",
  WAHCCSolutoShop:
    "[ShopName]様、お待たせいたしました。担当●●でございます。\nご担当者様のお名前をお願い致します。\n\nお客さまはご来店中でしょうか。\n\n【来店中の場合】\nお客さまのご本人確認はお済みでしょうか。\n\n①本人確認済の場合（電話口：ショップ担当者）\n→ありがとうございます。続きまして4点確認させてください。\nまずはご契約のお電話番号をお願いします。\nご契約者様のお名前をフルネームでお願いします。\n本人確認は暗証番号で実施されましたか？（暗証番号/生年月日/住所）\nご来店者様はご契約者様ご本人様でよろしいでしょうか？\n\n②本人確認未実施の場合\n→かしこまりました。では私からお客さまに直接確認させていただきます。\nお電話をかわっていただくことは可能でしょうか？\n\n（電話口：お客さま）\nお待たせいたしました。担当●●でございます。\nご契約のお電話番号を教えていただけますでしょうか。\n\nありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n最後に恐れいりますが、ご契約者様の生年月日をお願いします。\n\n【来店中ではない場合】\n申し訳ございませんが、お客さまがいらっしゃる状態でないと受付ができかねますので、お客さまからおかけなおしをお願いいたします。",
  MDNNGCall:
    "お待たせいたしました。ガイダンスの操作にご協力いただきありがとうございます。ここからは担当●●が承ります。\n恐れ入りますが、今一度ご契約のお電話番号を教えていただけますでしょうか。\nありがとうございます。続きまして3点確認させてください。\nまずはご契約者様のお名前をフルネームで教えていただけますでしょうか？\n電話口のお客さまは契約者ご本人様でしょうか？\n最後に、ご契約者様の生年月日をお願いします。\n\n【対話者が契約者本人の場合】\n（対応を進める）〇〇様、いつもご利用いただきありがとうございます。 本日は、いかがなさいましたか。\n\n【対話者が契約者本人以外（代理人）からの入電】\n・電話口のお客さまのお名前をフルネームで教えていただけますか。\n・電話口のお客さまとご契約者様とのご関係を教えていただけますか。\n・電話口のお客さまが普段この端末をお使いになっているということでしょうか。\n・本日のお問合せ内容はご契約者様もご存知でしょうか。\nいつもご利用いただきありがとうございます。\n本日はいかがなさいましたか。\n\n【対話者が第三者の場合】\n申し訳ございませんが、契約者ご本人様からおかけなおしいただきますようお願いいたします。",
};

export const keyNameMappings = {
  ExtenionID: "転送用ID",
  route: "業務",
  mdn: "MDN",
  pin: "PIN ",
  subscribername: "契約氏名",
  contractmdn: "契約番号",
  Queue: "Queue",
};

export const appColors = {
  primaryGreen: "#117E26",
  primaryOrange: "#E18B24",
  secondaryBlue: "#077398",
  primaryBlue: "#0A4A8D",
  primaryGrey: "#666666",
  primaryRed: "#D0021B",
  primaryACWHold: "#E18B24",
  primaryBlack: "#333333",
  primaryPink: "#D04901",
};

export const voiceBotData = {
  MDN: "電話番号",
  Contractor: "話者本人",
  Name: "氏名 ",
  DOB: "生年月日",
};

export const popupdata = {
  message: "もう一度IRIS画面を起動しますか？",
  yes: "はい",
  no: "いいえ",
};
