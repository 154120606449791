export const stylesEle = {
  overlay: {
    bottom: "0",
    left: "0",
    right: "0",
    top: "0",
    boxSizing: "border-box",
    position: "fixed",
    touchAction: "none",
    zIndex: "1300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

};

export const FullScreenCircularProgress = () => (
  <div style={stylesEle.overlay}>
    <div class="loader"></div>
  </div>
);
