import React, { useEffect, useState } from "react";
import "@awsui/global-styles/index.css";
import { Button, Select, Option } from "@uap/uap-ui-components";
import { FullScreenCircularProgress } from "./FullScreenCircularProgress";
import { positionLookup, skillLookup } from "../local/mockdata";

export const stylesEle = {
  headerdiv: {
    padding: "20px",
    minHeight: "92vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  styledropdown: {
    height: "40px",
    marginBottom: "20px",
  },
  styledropdownend: {
    height: "40px",
  },
  stylebtntext: {
    textAlign: "center",
  },
  stylebtn: {
    width: "100%",
    border: "none",
    height: "32px",
  },
};
const NewAgentInfo = (props) => {
  const { handleOk } = props;
//   const [skillData, setSkillData] = useState([]);
//   const [positionData, setPositionData] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [selectedSkil, setSelectedSkil] = useState("");
  const [selectedPosition, setSelectedPostion] = useState("");
  const [isLoading, setIsLoading] = useState(true);
const [status, setStatus] = useState("");
  const handlePositionChange = (event) => {
    console.log({ event });
    setSelectedPostion(event.target.value);
  };
//   useEffect(() => {
//     setIsLoading(true);
//     getCarrierInfo({}).then((res) => {
//       const data = res?.data[0]?.LookupContextResponse;
//       const positionData = res?.data[1]?.LookupContextResponse;
//       setSkillData(data?.LookupContexts);
//       setPositionData(positionData?.LookupContexts);
//       setIsLoading(false);
//       savelog("info", `Application Loaded`, "");
//     });
//   }, []);
  // Avoid re-rendering ConnectCCP
  const openCustomerinfo = () => {
    let obj = {
      application: selectedApplication,
      skill: selectedSkil,
      position: selectedPosition,
    };
    handleOk(obj);
  };

  const handledDisable = () => {
    const data =
      selectedApplication === "" ||
      selectedPosition === "" ||
      selectedSkil === ""
        ? false
        : true;
        console.log("dataaaaaaaaaaaaaa", data, selectedApplication, selectedPosition, selectedSkil)
    return selectedApplication === "" ||
      selectedPosition === "" ||
      selectedSkil === ""
      ? true
      : false;
  };

  useEffect(() => {
    handledDisable();
  }, [selectedApplication, selectedPosition, selectedSkil]);
  return (
    <>
      <div style={stylesEle.headerdiv}>
        <div>
          <Select
            useDocumentContainer={true}
            name={"application"}
            fullWidth
            label={"サービス"}
            onChange={(e) => setSelectedApplication(e.target.value)}
             style={stylesEle.styledropdown}
            selectedValue={selectedApplication}
          >
    { console.log("insideeeeeeeeeeeee newwww")}
            <Option value={"horizon"}>故障紛失サポート</Option>
            <Option value={"soluto"}>使い方サポート</Option>
          </Select>
          <Select
            useDocumentContainer={true}
            style={stylesEle.styledropdown}
            label={"スキル"}
            onChange={(e) => {
              setSelectedSkil(e.target.value);
            }}
         selectedValue={selectedSkil ? selectedSkil : ""}
            fullWidth
          >
             {skillLookup.map((status) => (
                        <option key={status.description} value={status.description}>
                          {status.LookupCode}
                        </option>
                      ))}
          </Select>
          <Select
            useDocumentContainer={true}
            onChange={(e) => {
                setSelectedSkil(e.target.value);
              }}
              selectedValue={selectedSkil ? selectedSkil : ""}         
                 style={stylesEle.styledropdownend}
            label={"役職"}
            fullWidth
          >
            
            {positionLookup.map((status) => (
                        <option key={status.description} value={status.description}>
                          {status.LookupCode}
                        </option>
                      ))}
          </Select>
        </div>
        <div style={stylesEle.stylebtntext}>
          <Button
            style={{
              ...stylesEle.stylebtn,
              cursor: !handledDisable() ? "pointer" : "restricated",
            }}
            variant="contained"
            // disabled={handledDisable()}
            onClick={openCustomerinfo}
          >
            開始
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewAgentInfo;