import React, { useState } from "react";
import mdnok from "../../src/assets/mdnok.svg";
import pinNotok from "../../src/assets/pinNotok.svg";

const IncommingCall = ({ onAccept, onReject }) => {
  const [status, setStatus] = useState("available");
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <div
      style={{
        height: "95vh",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "green"}}>
      <div
        style={{
          height: "40px",
          backgroundColor: "green",
          color: "white",
          fontSize: "18px",
          display: "flex",
          paddingTop: "4px",
          paddingLeft: "10px",
        }}
      >
        080-11-22-33-44
      </div>
      <div style={{
        backgroundColor: "green",
        color: "white",
        fontSize: "12px",
        display: "flex",   
        marginTop: "20px",
      }}>
Incomming Call
      </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "end",
          paddingBottom: 20,
        }}
      >
        <button
          name="Acceptcall"
          style={{
            justifyContent: "center",
            marginRight: "10px",
            backgroundColor: "Green",
            width: "20%",
            height: "40px",
            color: "white",
            fontSize: "14px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
          onClick={onAccept}
        >
          <img
            src={mdnok}
            height={20}
            width={20}
            style={{ alignSelf: "center", paddingRight: 5 }}
          />
          Accept Call{" "}
        </button>
        <button
          name="Rejectcall"
          style={{
            justifyContent: "center",
            backgroundColor: "Red",
            width: "20%",
            height: "40px",
            color: "white",
            fontSize: "14px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
          onClick={onReject}
        >
          <img
            src={pinNotok}
            height={20}
            width={20}
            style={{ alignSelf: "center", paddingRight: 5 }}
          />
          Reject Call{" "}
        </button>
      </div>
    </div>
    // </div>
  );
};
export default IncommingCall;
