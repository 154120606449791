import "amazon-connect-streams";
import React, { memo, useRef, useEffect } from "react";
import { genLogger } from "../lib";

const stylesEle = {
  renderdiv: {
    width: "100%",
    height: "100%",
    border: "none",
    PointerEvent: "none",
  },
};
const name = "ConnectCCP";
const { log, error } = genLogger(name);

const ConnectCCP = (props) => {
  const { islogout } = props;
  const ref = useRef();
  useEffect(() => {
    const ccpUrl = process.env.REACT_APP_CCP_URL;
    const loginUrl = process.env.REACT_APP_CCP_SAML_LOGIN_URL;
    const ssoLoginURL = loginUrl ? { loginUrl: loginUrl } : undefined;
    try {
      log("init start");
      if (typeof window === "undefined") throw new Error("window missing");
      if (typeof window.connect === "undefined")
        throw new Error("global connect missing");
      window.connect.core.initCCP(ref.current, {
        ccpUrl: ccpUrl,
        ...ssoLoginURL,
        loginPopup: true,
        loginPopupAutoClose: true,
        pageOptions: {
          enableAudioDeviceSettings: true,
          enablePhoneTypeSettings: false,
        },
        softphone: { allowFramedSoftphone: false },
      });
      var eventBus = window.connect.core.getEventBus();

      // connect.EventType.TERMINATED is a constant containing the name of the event.
      eventBus.subscribe(window.connect.EventType.TERMINATED, function () {
        islogout(true);
        console.log("logged out");
      });
    } catch (e) {
      error(e);
    }
  }, [ref]);

  log("render");
  return <div ref={ref} style={stylesEle.renderdiv} />;
};

export default memo(ConnectCCP);
