import React, { useState } from "react";
import chat from "../../src/assets/chat.png";

const InitialCCPPage = () => {
  const [status, setStatus] = useState("available");
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
          <div>
        {/* <div
          style={{
            width: "100%",
            height: "40px",
            backgroundColor: "black",
            color: "white",
            fontSize: "18px",
            display: "flex",
          }}
        >
          <select
            style={{
              height: "40px",
              backgroundColor: "black",
              color: "white",
              width: "50%",
              paddingLeft: "10px",
            }}
            id="status"
            value={status}
            onChange={handleStatusChange}
          >
          <option value="available">オフライン</option> 
        <option value="offline">受付可</option> 
        <option value="offline">離席中</option> 
          </select>
        </div> */}
        <div style={{ textAlign: "center", marginTop: "12%" }}>
            
          <h1> Welcome to CCP</h1>
          <h1>
            <img style={{}}  src={chat}></img>
          </h1>

        </div>
      </div>
  );
};
export default InitialCCPPage;
