import React from "react";

const CallFromShopComponent = ({ shopData }) => {
  const showName = shopData?.Name
    ? shopData?.Name
    : shopData?.ClientStoreAttribute?.BUSINESSNAMEKANA
    ? shopData?.ClientStoreAttribute?.BUSINESSNAMEKANA
    : "";
  if (shopData) {
    return (
      <div
        style={{
          marginTop: "20px",
          color: "white",
          fontSize: "18px",
          paddingLeft: "16px"
        }}
      >
        <div style={{ marginBottom: "10px" }}>{`{{${showName}}}`} </div>
        <div style={{ marginBottom: "10px" }}>
          {`{{${shopData?.ClientStoreAttribute?.BUSINESSNAMEKANA || ""}}}`}
        </div>
        <div>{`{{${shopData?.ClientStoreAttribute?.StoreCode || ""}}}`}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default CallFromShopComponent;
