import axios from "axios";
import { configEnv } from "../config/config";

const BASE_URL = process.env.REACT_APP_ENDPOINT_API;
const BASE_URL_UAP_GRAPHQL = process.env.REACT_APP_UAP_GRAPHQL_URL;
const env = configEnv?.authConfig?.clientId;
const getAccesstoken = sessionStorage.getItem(`UapAuth${env}_access_token`);

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAccesstoken,
  },
});

const apiClientHorizon = axios.create({
  baseURL: BASE_URL_UAP_GRAPHQL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAccesstoken,
  },
});

// Define common API methods
const _get = (url, config = {}) => {
  return apiClient.get(url, config);
};

const _delete = (url, config = {}) => {
  return apiClient.delete(url, config);
};

const _put = (url, data = {}, config = {}) => {
  return apiClient.put(url, data, config);
};

const _post = (url, data = {}, config = {}) => {
  return apiClient.post(url, data, config);
};

const _postHorizon = (url, data = {}, config = {}) => {
  return apiClientHorizon.post(url, data, config);
};

// Export API methods
export { _get, _delete, _put, _post, _postHorizon };
