import React from "react";
import pinNoteok from "../../src/assets/pinNotok.svg";
import { voiceBotData } from "../local/commonscript";

const VoiceBotDataComponent = ({ data }) => {
  console.log({ data }, "data for voice bot");

  const renderVoiceBotValue = (value) => {
    return value === "Matched" ? (
      <div>✅</div>
    ) : value === "NotMatched" ? (
      <img style ={{marginLeft: "3px"}}src={pinNoteok} alt="pinNoteOk" />
    ) : (
      <div style={{ marginLeft: "8px" }}>{"-"}</div>
    );
  };
  const voiceBotDetails = (lable, value) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          color: "#fff",
          paddingLeft: "16px"
        }}
      >
        <div style={{ flex: 0.3 }}>{lable}</div>
        <div style={{ flex: 0.08 }}>:</div>
        <div style={{ fontSize: "20px", flex: 0.6 }}>
          {renderVoiceBotValue(value)}
        </div>
      </div>
    );
  };
  return (
    <div style={{ margin: "10px 0px 10px 0px" }}>
      {voiceBotDetails(voiceBotData.MDN, data.MDN)}
      {voiceBotDetails(voiceBotData.Contractor, data.Contractor)}
      {voiceBotDetails(voiceBotData.Name, data.Name)}
      {voiceBotDetails(voiceBotData.DOB, data.DOB)}
    </div>
  );
};

export default VoiceBotDataComponent;
