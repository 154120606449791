import { common } from "./commonscript";
import { _get, _post, _postHorizon } from "../request/axiosRequest";

const MIN_LENGTH_RSA_2048_ENCRYPTED = 344;

export const decrypt = async (cipherText) => {
  if (
    typeof cipherText !== "string" ||
    cipherText?.length < MIN_LENGTH_RSA_2048_ENCRYPTED
  ) {
    return cipherText;
  }
  const URL = process.env.REACT_APP_ENDPOINT_API;
  const url = `${URL}/decrypt`;
  try {
    const axiosResponseData = await _post(url, { CipherText: cipherText });
    return axiosResponseData.data?.PlainText;
  } catch (error) {}
};

export const savelog = async (Level, Message, Context) => {
  const URL = process.env.REACT_APP_HZ_URL;
  const url = `${URL}/utilityservices/v1/log`;
  const payload = {
    LoggerRequest: {
      Level: Level,
      Context: Context,
      Message: "CCPUI " + Message,
    },
  };
  try {
    const axiosResponseData = await _postHorizon(url, payload);
    return axiosResponseData.data;
  } catch (error) {}
};

export const getUserPermissionDetails = async (userName) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      'query GetUserDetails($UserId: String!) { GetUserPermissions(UserId: $UserId Version: "v5") { UserId FirstName LastName DisplayName TeamName Sites UserAttributes { UserAttributesId AttributeName AttributeValue } Applications { ApplicationId ApplicationName Profiles { ProfileId ProfileName Roles { RoleName RoleId Functionality { FunctionalityName Permissions } } } Site { SiteName SiteId } Teams { TeamName TeamId } } SeatMap { SeatId SeatName FloorId ReservationDate } } }',
    variables: {
      UserId: userName,
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData.data;
  } catch (error) {}
};

export const getAgentLocation = async () => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "query GetSiteFloors { GetSiteFloors { Floors { FloorId FloorName FloorStatus FloorColsCount FloorRowsCount FloorType } } }",
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData;
  } catch (error) {}
};

export const getSiteMap = async () => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "query GetSiteFloors { GetSiteFloors { Floors { FloorId FloorName FloorStatus FloorColsCount FloorRowsCount FloorType } } }",
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData.data;
  } catch (error) {}
};

export const getSeatMap = async (floorId) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "query GetSeatMap($floorId: String!) { GetSeatMap(FloorId: $floorId) { FloorId FloorName FloorRowsCount FloorColsCount FloorStatus FloorType Seats { SeatId SeatName SeatStatus SeatRowIndex SeatColIndex Owner AgentStatus ReservationDate OnGoingCall { ContactId ContactStartTime KeywordName KeywordCount KeywordObservedTime } }FloorItems { ItemId ItemName ItemType ItemStatus ItemDescription FloorItemRowIndex FloorItemColIndex } } }",
    variables: {
      floorId: floorId,
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData.data;
  } catch (error) {}
};

export const saveSkill = async (skill, contactSessionId, agentName) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "mutation UpdateCcpInfo($updateCcpInfoRequest: UpdateCcpInfoRequest!) { UpdateCcpInfo(UpdateCcpInfoRequest: $updateCcpInfoRequest) { CcpInfoUpdateResponse { message } } }",
    variables: {
      updateCcpInfoRequest: {
        CcpInfoUpdateRequest: {
          contactSessionId: contactSessionId,
          skill: skill,
        },
      },
    },
  };
  let config = {
    headers: {
      "asurion-enduser": agentName,
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString, config);
    return axiosResponseData.data;
  } catch (error) {}
};

export const saveUCPDetails = async (skill, application, agentName, site) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "mutation SaveUCPDetail($input: SaveUCPDetailRequest!) { SaveUCPDetail(input: $input) { Success } }",
    variables: {
      input: {
        UserName: agentName,
        Skill: skill,
        Service: application,
        Site: site,
      },
    },
  };
  let config = {
    headers: {
      "asurion-enduser": agentName,
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString, config);
    return axiosResponseData.data;
  } catch (error) {}
};

export const reserveSeat = async (seatId, agentName) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let seatStatus = "Reserved";
  let queryString = {
    query:
      "mutation ReserveSeat($input:ReserveSeatRequest!) { ReserveSeat(input:$input ) { Status Message } }",
    variables: {
      input: {
        SeatId: seatId,
        Status: seatStatus,
      },
    },
  };
  let config = {
    headers: {
      "asurion-enduser": agentName,
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString, config);
    return axiosResponseData.data;
  } catch (error) {}
};

export const unreserveSeat = async (seatId) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let seatStatus = "Unreserved";
  let queryString = {
    query:
      "mutation ReserveSeat($input:ReserveSeatRequest!) { ReserveSeat(input:$input ) { Status Message } }",
    variables: {
      input: {
        SeatId: seatId,
        Status: seatStatus,
      },
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData.data;
  } catch (error) {}
};

export const saveInteractionDetails = async (contactSessionId, action) => {
  const URL = process.env.REACT_APP_UAP_GRAPHQL_URL;
  const url = `${URL}`;
  let queryString = {
    query:
      "mutation UpdateCcpInfo($updateCcpInfoRequest: UpdateCcpInfoRequest!) { UpdateCcpInfo(UpdateCcpInfoRequest: $updateCcpInfoRequest) { CcpInfoUpdateResponse { message } } }",
    variables: {
      updateCcpInfoRequest: {
        CcpInfoUpdateRequest: {
          contactSessionId: contactSessionId,
          action: action,
        },
      },
    },
  };
  try {
    const axiosResponseData = await _post(url, queryString);
    return axiosResponseData.data;
  } catch (error) {}
};
export const extensionId = async (userName) => {
  const URL = process.env.REACT_APP_HZ_URL;
  const url = `${URL}/usermanagement/v4/user/${userName}`;
  try {
    const axiosResponseData = await _get(url);
    return axiosResponseData.data;
  } catch (error) {}
};

export const getAgentCount = async () => {
  const URL = process.env.REACT_APP_ENDPOINT_API;
  const url = `${URL}/agentavailability`;
  const payload = {
    FlowName: "CCP",
    QueueId: sessionStorage.getItem("queueId"),
  };
  try {
    const axiosResponseData = await _post(url, payload);
    return axiosResponseData.data;
  } catch (error) {}
};
export const callfromShop = async () => {
  const URL = process.env.REACT_APP_HZ_URL;

  const callingNo = sessionStorage.getItem("CallingNo");
  const url = `${URL}/caseadministration/v1/clientstore/graph?clientid=42818A88285511E9A38A063D3002FD4C&clientchannelid=7D42D83E285511E9A38A063D3002FD4C&callingnumber=${callingNo}`;
  try {
    const axiosResponseData = await _get(url);
    return axiosResponseData.data;
  } catch (error) {}
};
export function toLocalNumber(phoneNumber) {
  if (phoneNumber?.startsWith("+81")) {
    return "0" + phoneNumber.replace(/D/g, "").slice(-10);
  } else {
    return phoneNumber;
  }
}

export const extractCalInfo = async (attr = {}) => {
  attr.mdn = attr.Mdn || {};
  attr.subscribername = attr.CustomerName || {};
  attr.pin = attr.CustomerPin || {};
  attr.Queue = attr?.Queue || {};
  attr.mdnok = attr?.MdnVerificationResult || {};
  attr.pinok = attr?.PinVerificationResult || {};
  attr.ucID = attr?.ContactSessionId || {};
  attr.VDN = attr?.OutputVdn || {};
  attr.botMDN = attr?.BotMDNVerificationResult || {};
  attr.voicebotContractor = attr?.ContractorVerificationStatus || {};
  attr.voicebotName = attr?.ContractorNameVerificationStatus || {};
  attr.DOB = attr?.BirthdateVerificationStatus || {};
  attr.IsVoiceBotCalled = attr?.IsVoiceBotCalled || {};
  attr.FlowName = attr?.FlowName || {};
  attr.callId = attr?.callId || {};
  attr.reservationId = attr?.reservationId || {};
  attr.callingNo = attr?.CallingNumber || {};
  attr.VoiceBotEmergencyQueueEnabled =
    attr?.VoiceBotEmergencyQueueEnabled || {};
  attr.IsUapEmergencyQueueEnabled = attr?.IsUapEmergencyQueueEnabled || {};
  attr.ShouldTransferToVoiceBot = attr?.ShouldTransferToVoiceBot || {};

  const mdn = await decrypt(attr.Mdn?.value);
  const subscribername = await decrypt(attr.subscribername?.value);
  const pin = await decrypt(attr.pin?.value);
  const Queue = attr.Queue?.value;
  const talkscript = attr.TalkScript?.value;
  const pinok = attr.PinVerificationResult?.value;
  const mdnok = attr.MdnVerificationResult?.value;
  const ucID = attr.ContactSessionId?.value;
  const VDN = attr.OutputVdn?.value;
  const contractmdn = await decrypt(attr.Mdn?.value);
  const botMDN = attr?.BotMDNVerificationResult?.value;
  const voicebotContractor = attr?.voicebotContractor?.value;
  const voicebotName = attr?.voicebotName?.value;
  const DOB = attr?.DOB?.value;
  const IsVoiceBotCalled = attr?.IsVoiceBotCalled?.value;
  const flowName = attr?.FlowName?.value;
  const callId = attr?.callId?.value;
  const reservationId = attr?.reservationId?.value;
  const callingNo = attr?.CallingNumber?.value || {};
  const VoiceBotEmergencyQueueEnabled =
    attr?.VoiceBotEmergencyQueueEnabled?.value;
  const IsUapEmergencyQueueEnabled = attr?.IsUapEmergencyQueueEnabled?.value;
  const ShouldTransferToVoiceBot = attr?.ShouldTransferToVoiceBot?.value;

  return {
    mdn,
    subscribername,
    pin,
    Queue,
    talkscript,
    mdnok,
    pinok,
    ucID,
    VDN,
    contractmdn,
    botMDN,
    voicebotName,
    voicebotContractor,
    DOB,
    IsVoiceBotCalled,
    flowName,
    callId,
    reservationId,
    callingNo,
    VoiceBotEmergencyQueueEnabled,
    ShouldTransferToVoiceBot,
    IsUapEmergencyQueueEnabled,
  };
};

// launch Soluto with params

export const launchSoluto = (arg, selectedData, seatMapInfo, ShopID) => {
  let solutoBaseUrl = process.env.REACT_APP_IRIS_OPEN_URL;
  // const MDN = arg?.Mdn;
  const UCID = arg?.ucID;
  const VDN = arg?.VDN;
  const application = selectedData?.application;
  const skill = selectedData.skill;
  const position = selectedData.position;
  const site = seatMapInfo?.site;
  const seat = seatMapInfo?.seat;
  let siteAvailable = site && site !== "" ? `&site=${seat}` : "";
  let seatAvailable = seat && seat !== "" ? `&seat=${seat}` : "";
  const ShopIDAvailable = ShopID ? `&shopID=${ShopID}` : "";
  if (ShopID) {
    const solutoUrlShop = `${solutoBaseUrl}?client=au&UCID=${UCID}&VDN=${VDN}&application=${application}&skill=${skill}&position=${position}${siteAvailable}${seatAvailable}${ShopIDAvailable}`;
    return solutoUrlShop;
  } else {
    const solutoUrlMDN = `${solutoBaseUrl}?client=au&UCID=${UCID}&VDN=${VDN}&application=${application}&skill=${skill}&position=${position}${siteAvailable}${seatAvailable}`;
    return solutoUrlMDN;
  }
};

const agentLocation = () => {
  const loc = sessionStorage.getItem("agent-location");
  if (loc === "call center") {
    return true;
  } else {
    return false;
  }
};
const handleFlowName = () => {
  const AllDataNew = localStorage.getItem("AllData")
    ? JSON.parse(localStorage.getItem("AllData"))
    : {};
  const flowName = AllDataNew?.flowName;
  if (
    flowName === "Hankyo" ||
    flowName === "HP" ||
    flowName === "CsAutoTransfer"
  ) {
    return true;
  } else {
    return false;
  }
};

const renderFormattedScript = (script, label, data, ShopName) => {
  const Shopname = label === "ShopName" ? true : false;
  const OKOKScript =
    label === "OKOkcase" ||
    label === "VoiceBotAllOk" ||
    label === "VoiceBotNameOk"
      ? true
      : false;
  if (OKOKScript) {
    return script.replace("[ContractName]", data.subscribername);
  } else if (Shopname) {
    return script.replace("[ShopName]", ShopName);
  } else {
    return script;
  }
};
export const renderScript = (data, ShopName, ShopID) => {
  const UNIDENTIFIED = "Unidentified";
  const NOT_MATCHED = "NotMatched";

  const isUnidentified = (value) =>
    value === UNIDENTIFIED || value === "" || value === "-";
  const isMatched = (value) => value === "Matched";

  const getVoicebotName = (data) =>
    isUnidentified(data?.voicebotName) ? NOT_MATCHED : data?.voicebotName;
  const getBirthDate = (data) =>
    isUnidentified(data?.DOB) ? NOT_MATCHED : data?.DOB;
  const getContractor = (data) =>
    isUnidentified(data?.voicebotContractor)
      ? NOT_MATCHED
      : data?.voicebotContractor;

  const MDN_OK = data?.mdnok === "true";
  const MDN_NG = !MDN_OK;
  const voicebotName = getVoicebotName(data);
  const birthDate = getBirthDate(data);
  const contractor = getContractor(data);

  const renderShopScript = () => {
    if (agentLocation() && !data?.mdn) {
      return renderFormattedScript(
        common.CCSolutoShop,
        "ShopName",
        data,
        ShopName
      );
    } else if (!agentLocation() && !data?.mdn) {
      return renderFormattedScript(
        common.WAHSolutoShop,
        "ShopName",
        data,
        ShopName
      );
    } else if (data?.mdn) {
      return renderFormattedScript(
        common.WAHCCSolutoShop,
        "ShopName",
        data,
        ShopName
      );
    }
    return null;
  };

  if (!ShopID && data?.flowName !== "SolutoShop") {
    if (!MDN_NG && !data?.IsVoiceBotCalled) {
      if (!ShopID) {
        if (data?.pinok === "true" && handleFlowName()) {
          return renderFormattedScript(
            agentLocation() ? common.CCAllOk : common.WAHAllOk,
            "OKOkcase",
            data
          );
        } else if (
          data?.flowName === "SolutoOutbound" &&
          ShopID === undefined
        ) {
          return renderFormattedScript(common.SolutoWAHAllOk, "OKOkcase", data);
        } else if (data?.pinok === "false" && handleFlowName()) {
          return agentLocation() ? common.CCOKNG : common.WAHOKNG;
        }
      } else {
        return renderFormattedScript(
          common.WAHCCSolutoShop,
          "ShopName",
          data,
          ShopName
        );
      }
    } else if (
      MDN_NG &&
      data?.IsVoiceBotCalled === "true" &&
      handleFlowName() &&
      data?.botMDN === "Matched"
    ) {
      if (!ShopID) {
        if (!agentLocation()) {
          if (
            isMatched(birthDate) &&
            isMatched(contractor) &&
            isMatched(voicebotName)
          ) {
            return renderFormattedScript(
              common.WAHVoiceBotAllOk,
              "VoiceBotAllOk",
              data
            );
          } else if (
            !isMatched(birthDate) &&
            !isMatched(contractor) &&
            !isMatched(voicebotName)
          ) {
            return common.WAHVoiceBotMDNOK;
          } else if (
            !isMatched(birthDate) &&
            isMatched(contractor) &&
            !isMatched(voicebotName)
          ) {
            return common.WAHVoiceBotContractorOk;
          } else if (
            !isMatched(birthDate) &&
            isMatched(contractor) &&
            isMatched(voicebotName)
          ) {
            return renderFormattedScript(
              common.WAHVoiceBotNameOk,
              "VoiceBotNameOk",
              data
            );
          }
        } else {
          if (
            isMatched(birthDate) &&
            isMatched(contractor) &&
            isMatched(voicebotName)
          ) {
            return renderFormattedScript(
              common.CCVoiceBotAllOk,
              "VoiceBotAllOk",
              data
            );
          } else if (
            !isMatched(birthDate) &&
            !isMatched(contractor) &&
            !isMatched(voicebotName)
          ) {
            return common.CCVoiceBotMDNOK;
          } else if (
            !isMatched(birthDate) &&
            isMatched(contractor) &&
            !isMatched(voicebotName)
          ) {
            return common.CCVoiceBotContractorOk;
          } else if (
            !isMatched(birthDate) &&
            isMatched(contractor) &&
            isMatched(voicebotName)
          ) {
            return renderFormattedScript(
              common.CCVoiceBotNameOk,
              "VoiceBotNameOk",
              data
            );
          }
        }
      } else {
        return renderFormattedScript(
          common.WAHCCSolutoShop,
          "ShopName",
          data,
          ShopName
        );
      }
    } else if (
      (data?.botMDN === "" ||
        data?.botMDN === UNIDENTIFIED ||
        data?.botMDN === NOT_MATCHED ||
        data?.VoiceBotEmergencyQueueEnabled === "true" ||
        data?.ShouldTransferToVoiceBot === "true" ||
        data?.IsUapEmergencyQueueEnabled === "true") &&
      data?.mdnok === "false" &&
      !ShopID
    ) {
      return common.MDNNGCall;
    }
  } else if (ShopID && data?.flowName === "SolutoShop") {
    return renderShopScript();
  } else if (!ShopID && data?.flowName === "SolutoShop") {
    if (agentLocation() && !data?.mdn) {
      return common.CCNotRegSolutoShop;
    } else if (!agentLocation() && !data?.mdn) {
      return common.WAHNotRegSolutoShop;
    }
  } else {
    return renderFormattedScript(
      common.WAHCCSolutoShop,
      "ShopName",
      data,
      ShopName
    );
  }
};

export const renderTalkScript = (data, ShopName, ShopId) => {
  if (data?.mdnok === "true" && data?.pinok === "true" && data?.flowName !== "SolutoShop") {
    return renderFormattedScript(common.CCAllOk, "OKOkcase", data);
  } else if (
    data?.mdnok === "false" &&
    data?.pinok === "false" &&
    data?.IsVoiceBotCalled === "true" &&
    data?.DOB === "Matched" &&
    data?.voicebotContractor === "Matched" &&
    data?.voicebotName === "Matched" &&
    data?.botMDN === "Matched"
  ) {
    return renderFormattedScript(
      common.CCVoiceBotAllOk,
      "VoiceBotNameOk",
      data
    );
  } else if (
    data?.IsVoiceBotCalled === "true" &&
    data?.botMDN === "Matched" &&
    data?.DOB === "Unidentified" &&
    data?.voicebotContractor === "Unidentified"
  ) {
    return renderFormattedScript(
      common.CCVoiceBotMDNOK,
      "VoiceBotNameOk",
      data
    );
  } else if (data?.flowName=== "SolutoShop" && data?.IsVoiceBotCalled === "false") {
    return renderFormattedScript(
      common.CCSolutoShop,
      "ShopName",
      data,
      ShopName
    );
  }
};

export const dummyIVRdata = {
  mdn: "07011223344",
  subscribername: "ｴｲﾕｳｼﾞｭｳﾊﾁｺﾞｳ",
  pin: "3456",
  contractmdn: "07011223344",
  Queue: "反響_ONB_MDNOK",
  mdnok: "true",
  pinok: "true",
  route: "スキル10",
  botMDN: "UnMatched",
  voicebotName: "Matched",
  voicebotContractor: "Matched",
  DOB: "-",
  shopData: true,
};

export const dummyvoiceBotdata = {
  mdn: "07011223344",
  subscribername: "ｴｲﾕｳｼﾞｭｳﾊﾁｺﾞｳ",
  pin: "2345",
  contractmdn: "07011223344",
  Queue: "反響_ONB_Root1",
  mdnok: "false",
  pinok: "false",
  route: "スキル10",
  botMDN: "Matched",
  voicebotName: "Matched",
  voicebotContractor: "Matched",
  DOB: "Matched",
  IsVoiceBotCalled: "true",
  flowName: "Hankyo",
};

export const dummyvoiceBotdataAllOk = {
  mdn: "-",
  subscribername: "-",
  pin: "2345",
  contractmdn: "-",
  Queue: "反響_ONB_Root1",
  mdnok: "false",
  pinok: "false",
  route: "スキル10",
  botMDN: "Matched",
  voicebotName: "Unidentified",
  voicebotContractor: "Unidentified",
  DOB: "Unidentified",
  IsVoiceBotCalled: "true",
  flowName: "Hankyo",
};
export const dummyVoiceBotDataMdnNotOk = {};

export const dummyShopdata = {
  mdn: "07011223344",
  subscribername: "ｴｲﾕｳｼﾞｭｳﾊﾁｺﾞｳ",
  pin: "3456",
  contractmdn: "07011223344",
  Queue: "SHOP_Onboarding",
  mdnok: "true",
  pinok: "true",
  route: "スキル10",
  IsVoiceBotCalled: "false",
  flowName: "SolutoShop",
};
