import logo from './logo.svg';
import React, { useCallback, useMemo, useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from '../src/components/Dashboard';


function App() {
  const Dash = useMemo(() => (Dashboard), []);
  return (
      <>
       
          <Router>
            <Route exact path="/" component={Dash} />
          </Router>
      </>
    );
  };
  
  export default App;
  