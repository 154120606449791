import React from "react";

const dateFormatter = (fromDate, toDate) => {
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const reservationDay = `${startDate.getMonth() + 1}/${startDate.getDate()}`;

  let startDateMinute = startDate.getMinutes();

  if (startDate.getMinutes() < 10) {
    startDateMinute = `0${startDate.getMinutes()}`;
  }

  const reservationStart = `${startDate.getHours()}:${startDateMinute}`;
  let endDateMinute = endDate.getMinutes();

  if (endDate.getMinutes() < 10) {
    endDateMinute = `0${endDate.getMinutes()}`;
  }
  const reservationEnd = `${endDate.getHours()}:${endDateMinute}`;
  const reservationTime = `${reservationDay} ${reservationStart}-${reservationEnd}`;
  return reservationTime;
};

const OutBoundDeatils = ({ reservationData, reservationSlotData }) => {
  const showData = () => {
    if (
      reservationData?.metadata?.customerOptOngoingCallSession &&
      reservationData?.metadata?.customerOptOngoingCallSession === true
    ) {
      return "再予約";
    } else {
      return "新規予約";
    }
  };

  const showComment = () => {
    return (
      reservationData?.comments && reservationData?.comments?.trim() !== ""
    );
  };

  if (
    reservationData?.slotId &&
    reservationData?.scheduleType === "Scheduled"
  ) {
    const showDate = dateFormatter(
      reservationSlotData?.data?.startDateTime,
      reservationSlotData?.data?.endDateTime
    );
    return (
      <div style={{ color: "white", fontSize: "18px", paddingLeft: "16px" }}>
        <div>
          <p>予約チャネル： </p>
          <p> {`{{${reservationData?.requestorOrigin}}}`} </p>
        </div>
        <div>
          <p>予約時間：</p>
          <p>{`{{${showDate}}}`}</p>
        </div>
        <div>
          <p>契約番号：</p>
          <p>{`{{${reservationData?.mdns?.[0]}}}`}</p>
        </div>
        {reservationData?.mdns?.[1] && (
          <div>
            <p>連絡先番号：</p>
            <p>{`{{${reservationData?.mdns?.[1]}}}`}</p>
          </div>
        )}
        <div>
          <p>再予約か否か：</p>
          <p>{`{{${showData()}}}`}</p>
        </div>
        {showComment() && (
          <div>
            <p>申し送り事項： </p>
            <p>{`{{${reservationData?.comments}}}`}</p>
          </div>
        )}
      </div>
    );
  } else if (reservationData?.scheduleType === "ASAP") {
    return (
      <div style={{ color: "white", fontSize: "18px", paddingLeft: "16px" }}>
        <div>
          <p>契約番号：</p>
          <p>{`{{${reservationData?.mdns?.[0]}}}`}</p>
        </div>
        {reservationData?.mdns?.[1] && (
          <div>
            <p>連絡先番号：</p>
            <p>{`{{${reservationData?.mdns?.[1]}}}`}</p>
          </div>
        )}
        {showComment() && (
          <div>
            <p>申し送り事項： </p>
            <p>{`{{${reservationData?.comments}}}`}</p>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
export default OutBoundDeatils;
