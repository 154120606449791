import { config as common } from "./common";

const environments = {
  localhost: common.sqa,
  default: common.sqa,
  "uap-telephony-ccp-sqa1.jpnmob-acyan.npr.aws.asurion.net": common.sqa,
  "uap-telephony-ccp-sqa2.jpnmob-acyan.npr.aws.asurion.net": common.sqa,
  "uap-telephony-ccp-uat.jpnmob-acyan.npr.aws.asurion.net": common.uat,
  "uap-telephony-ccp-uat1.jpnmob-acyan.npr.aws.asurion.net": common.uat,
  "uap-telephony-ccp-uat2.jpnmob-acyan.npr.aws.asurion.net": common.uat,
  "uap-telephony-ccp-trn.jpnmob-acyan.prd.aws.asurion.net": common.trn,
  "uap-telephony-ccp-stg.jpnmob-acyan.prd.aws.asurion.net": common.stg,
  "uap-telephony-ccp-stg1.jpnmob-acyan.prd.aws.asurion.net": common.stg,
  "uap-telephony-ccp-stg2.jpnmob-acyan.prd.aws.asurion.net": common.stg,
  "uap-telephony-ccp.jpnmob-acyan.prd.aws.asurion.net": common.prd,
  "uap-telephony-ccp-prd1.jpnmob-acyan.prd.aws.asurion.net": common.prd,
  "uap-telephony-ccp-prd2.jpnmob-acyan.prd.aws.asurion.net": common.prd,
};

export const configEnv = !environments[window.location.hostname]
  ? environments["default"]
  : environments[window.location.hostname];
