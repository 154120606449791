export const skillLookup = [
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】ATAC",
      "LookupDisplayName": "【故】ATAC",
      "LookupDescription": "A",
      "Active": 1,
      "Status": "ACTV",
      "Order": 1
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】SHOP",
      "LookupDisplayName": "【故】SHOP",
      "LookupDescription": "S",
      "Active": 1,
      "Status": "ACTV",
      "Order": 2
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】RAS",
      "LookupDisplayName": "【故】RAS",
      "LookupDescription": "R",
      "Active": 1,
      "Status": "ACTV",
      "Order": 3
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】FL",
      "LookupDisplayName": "【故】FL",
      "LookupDescription": "F",
      "Active": 1,
      "Status": "ACTV",
      "Order": 4
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】CSAT",
      "LookupDisplayName": "【故】CSAT",
      "LookupDescription": "C",
      "Active": 1,
      "Status": "ACTV",
      "Order": 5
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】OUT",
      "LookupDisplayName": "【故】OUT",
      "LookupDescription": "O",
      "Active": 1,
      "Status": "ACTV",
      "Order": 6
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】旧OUT",
      "LookupDisplayName": "【故】旧OUT",
      "LookupDescription": "旧O",
      "Active": 1,
      "Status": "ACTV",
      "Order": 7
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】法人OUT",
      "LookupDisplayName": "【故】法人OUT",
      "LookupDescription": "法O",
      "Active": 1,
      "Status": "ACTV",
      "Order": 8
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】法人旧OUT",
      "LookupDisplayName": "【故】法人旧OUT",
      "LookupDescription": "法旧O",
      "Active": 1,
      "Status": "ACTV",
      "Order": 9
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】法人IN",
      "LookupDisplayName": "【故】法人IN",
      "LookupDescription": "法I",
      "Active": 1,
      "Status": "ACTV",
      "Order": 10
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】案件",
      "LookupDisplayName": "【故】案件",
      "LookupDescription": "案",
      "Active": 1,
      "Status": "ACTV",
      "Order": 11
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】PilotRAS",
      "LookupDisplayName": "【故】PilotRAS",
      "LookupDescription": "PR",
      "Active": 1,
      "Status": "ACTV",
      "Order": 12
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】MF-Chat",
      "LookupDisplayName": "【故】MF-Chat",
      "LookupDescription": "MC",
      "Active": 1,
      "Status": "ACTV",
      "Order": 13
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】チャット",
      "LookupDisplayName": "【故】チャット",
      "LookupDescription": "チャット",
      "Active": 1,
      "Status": "ACTV",
      "Order": 14
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】持ち込み故サポ",
      "LookupDisplayName": "【故】持ち込み故サポ",
      "LookupDescription": "持ちサポ",
      "Active": 1,
      "Status": "ACTV",
      "Order": 15
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【故】TLC管理者",
      "LookupDisplayName": "【故】TLC管理者",
      "LookupDescription": "TLC",
      "Active": 1,
      "Status": "ACTV",
      "Order": 16
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Onboarding",
      "LookupDisplayName": "【つか】Onboarding",
      "LookupDescription": "O",
      "Active": 1,
      "Status": "ACTV",
      "Order": 17
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Technical",
      "LookupDisplayName": "【つか】Technical",
      "LookupDescription": "T",
      "Active": 1,
      "Status": "ACTV",
      "Order": 18
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Geek",
      "LookupDisplayName": "【つか】Geek",
      "LookupDescription": "G",
      "Active": 1,
      "Status": "ACTV",
      "Order": 19
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Message",
      "LookupDisplayName": "【つか】Message",
      "LookupDescription": "MSG",
      "Active": 1,
      "Status": "ACTV",
      "Order": 20
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Tech Lead",
      "LookupDisplayName": "【つか】Tech Lead",
      "LookupDescription": "TL",
      "Active": 1,
      "Status": "ACTV",
      "Order": 21
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Coach",
      "LookupDisplayName": "【つか】Coach",
      "LookupDescription": "C",
      "Active": 1,
      "Status": "ACTV",
      "Order": 22
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】Operation MGR",
      "LookupDisplayName": "【つか】Operation MGR",
      "LookupDescription": "OM",
      "Active": 1,
      "Status": "ACTV",
      "Order": 23
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "Site Director",
      "LookupDisplayName": "Site Director",
      "LookupDescription": "SiteDirector",
      "Active": 1,
      "Status": "ACTV",
      "Order": 24
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "KEV統括",
      "LookupDisplayName": "KEV統括",
      "LookupDescription": "統括",
      "Active": 1,
      "Status": "ACTV",
      "Order": 25
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "Acyan",
      "LookupDisplayName": "Acyan",
      "LookupDescription": "Acyan",
      "Active": 1,
      "Status": "ACTV",
      "Order": 26
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "GKIT",
      "LookupDisplayName": "GKIT",
      "LookupDescription": "GKIT",
      "Active": 1,
      "Status": "ACTV",
      "Order": 27
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "【つか】在宅",
      "LookupDisplayName": "【つか】在宅",
      "LookupDescription": "在宅",
      "Active": 1,
      "Status": "ACTV",
      "Order": 28
    },
    {
      "LookupContextCode": "SKILL",
      "LookupCode": "GKProduct",
      "LookupDisplayName": "GKProduct",
      "LookupDescription": "GKProduct",
      "Active": 1,
      "Status": "ACTV",
      "Order": 28
    }
  ]

  export const positionLookup = [
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "AGT",
        "LookupDisplayName": "AGT",
        "LookupDescription": "AGT",
        "Active": 1,
        "Status": "ACTV",
        "Order": 1
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "ALD",
        "LookupDisplayName": "ALD",
        "LookupDescription": "ALD",
        "Active": 1,
        "Status": "ACTV",
        "Order": 2
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "LD",
        "LookupDisplayName": "LD",
        "LookupDescription": "LD",
        "Active": 1,
        "Status": "ACTV",
        "Order": 3
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "SV",
        "LookupDisplayName": "SV",
        "LookupDescription": "SV",
        "Active": 1,
        "Status": "ACTV",
        "Order": 4
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Advisor",
        "LookupDisplayName": "Advisor",
        "LookupDescription": "Advisor",
        "Active": 1,
        "Status": "ACTV",
        "Order": 5
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Tech Lead",
        "LookupDisplayName": "Tech Lead",
        "LookupDescription": "Tech Lead",
        "Active": 1,
        "Status": "ACTV",
        "Order": 6
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Coach",
        "LookupDisplayName": "Coach",
        "LookupDescription": "Coach",
        "Active": 1,
        "Status": "ACTV",
        "Order": 7
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Operation MGR",
        "LookupDisplayName": "Operation MGR",
        "LookupDescription": "Operation MGR",
        "Active": 1,
        "Status": "ACTV",
        "Order": 8
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Site Director",
        "LookupDisplayName": "Site Director",
        "LookupDescription": "Site Director",
        "Active": 1,
        "Status": "ACTV",
        "Order": 9
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "KEV統括",
        "LookupDisplayName": "KEV統括",
        "LookupDescription": "KEV統括",
        "Active": 1,
        "Status": "ACTV",
        "Order": 10
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "Acyan",
        "LookupDisplayName": "Acyan",
        "LookupDescription": "Acyan",
        "Active": 1,
        "Status": "ACTV",
        "Order": 11
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "GKIT",
        "LookupDisplayName": "GKIT",
        "LookupDescription": "GKIT",
        "Active": 1,
        "Status": "ACTV",
        "Order": 12
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "GKProduct",
        "LookupDisplayName": "GKProduct",
        "LookupDescription": "GKProduct",
        "Active": 1,
        "Status": "ACTV",
        "Order": 13
    },
    {
        "LookupContextCode": "POSITION",
        "LookupCode": "TLC管理者",
        "LookupDisplayName": "TLC管理者",
        "LookupDescription": "TLC管理者",
        "Active": 1,
        "Status": "ACTV",
        "Order": 14
    }
]

export const agentStatus =  [
  {
    name: "オフライン",
    description: "Offline",
    displayOrder: 1,
    tags: { createdBy: "automated" },
  },
  {
    name: "受付可",
    description: "Available",
    displayOrder: 2,
    tags: { createdBy: "automated" },
  },
  {
    name: "離席中",
    description: "Away",
    displayOrder: 3,
    tags: { createdBy: "automated" },
  },
  {
    name: "1.短時間離席",
    description: "1.Short Break",
    displayOrder: 4,
    tags: { createdBy: "automated" },
  },
  {
    name: "2.Avail活用",
    description: "2.Avail use",
    displayOrder: 5,
    tags: { createdBy: "automated" },
  },
  {
    name: "3.セルフ発信（IN）",
    description: "3.Selfcall (IN)",
    displayOrder: 6,
    tags: { createdBy: "automated" },
  },
  {
    name: "4.緊急周知",
    description: "4.Emergency notification",
    displayOrder: 7,
    tags: { createdBy: "automated" },
  },
  {
    name: "5.二次対応業務（ユニット管理者のみ使用）",
    description: "5.Secondary response work (only for unit administrators)",
    displayOrder: 8,
    tags: { createdBy: "automated" },
  },
  {
    name: "6.障害・災害関連",
    description: "6.Failure/disaster related",
    displayOrder: 9,
    tags: { createdBy: "automated" },
  },
  {
    name: "7.施策系対応",
    description: "7.Policy response",
    displayOrder: 10,
    tags: { createdBy: "automated" },
  },
  {
    name: "8.端末不具合",
    description: "8.Terminal malfunction",
    displayOrder: 11,
    tags: { createdBy: "automated" },
  },
  {
    name: "9.各種面談",
    description: "9.Various interviews",
    displayOrder: 12,
    tags: { createdBy: "automated" },
  },
  {
    name: "10.ショートFB（管理者指示）",
    description: "10.Short FB (Administrator instructions)",
    displayOrder: 13,
    tags: { createdBy: "automated" },
  },
  {
    name: "11.定期FB（管理者指示）",
    description: "11.Regular FB (Administrator instructions)",
    displayOrder: 14,
    tags: { createdBy: "automated" },
  },
  {
    name: "12.チャット対応",
    description: "12.Chat support",
    displayOrder: 15,
    tags: { createdBy: "automated" },
  },
  {
    name: "14.転送NG折返し発信",
    description: "14.Call back when transfer is not possible",
    displayOrder: 16,
    tags: { createdBy: "automated" },
  },
  {
    name: "15.前処理（修理リスト発信)",
    description: "15.Preprocessing (repair list call)	",
    displayOrder: 17,
    tags: { createdBy: "automated" },
  },
  {
    name: "16.後処理（修理リスト発信)",
    description: "16.Postprocessing (repair list call)",
    displayOrder: 18,
    tags: { createdBy: "automated" },
  },
  {
    name: "17.お客様発信（修理/SNR/DR)",
    description: "17.Customer call (repair/SNR/DR)",
    displayOrder: 19,
    tags: { createdBy: "automated" },
  },
  {
    name: "18.メーカー発信（修理/SNR/DR)",
    description: "18.Manufacturer call (repair/SNR/DR)",
    displayOrder: 20,
    tags: { createdBy: "automated" },
  },
  {
    name: "19.SHOP発信（修理/SNR/DR)",
    description: "19.SHOP call (repair/SNR/DR)",
    displayOrder: 21,
    tags: { createdBy: "automated" },
  },
  {
    name: "23.修理Wチェック業務（修理)",
    description: "23.Repair double check work (repair)",
    displayOrder: 22,
    tags: { createdBy: "automated" },
  },
  {
    name: "24.メーカー対応業務",
    description: "24.Manufacturer response work",
    displayOrder: 23,
    tags: { createdBy: "automated" },
  },
  {
    name: "25.SNROrange登録業務",
    description: "25.SNROrange registration work",
    displayOrder: 24,
    tags: { createdBy: "automated" },
  },
  {
    name: "26.空端末業務対応（修理)",
    description: "26.Empty terminal work response (repair)",
    displayOrder: 25,
    tags: { createdBy: "automated" },
  },
  {
    name: "27.担当業務（発信)",
    description: "27.Assigned work (call)",
    displayOrder: 26,
    tags: { createdBy: "automated" },
  },
  {
    name: "28.担当業務（突発転送)",
    description: "28.Assigned work (sudden transfer)",
    displayOrder: 27,
    tags: { createdBy: "automated" },
  },
  {
    name: "29.担当業務（案件精査）",
    description: "29.Assigned work (case review)",
    displayOrder: 28,
    tags: { createdBy: "automated" },
  },
  {
    name: "30.担当業務（登録業務)",
    description: "30.Assigned work (registration)",
    displayOrder: 29,
    tags: { createdBy: "automated" },
  },
  {
    name: "31.自席研修",
    description: "31.Selfdesk training",
    displayOrder: 30,
    tags: { createdBy: "automated" },
  },
  {
    name: "46.IC①",
    description: "46.IC①",
    displayOrder: 31,
    tags: { createdBy: "automated" },
  },
  {
    name: "47.IC②",
    description: "47.IC②",
    displayOrder: 32,
    tags: { createdBy: "automated" },
  },
  {
    name: "48.IC③",
    description: "48.IC③",
    displayOrder: 33,
    tags: { createdBy: "automated" },
  },
  {
    name: "49.IC④",
    description: "49.IC④",
    displayOrder: 34,
    tags: { createdBy: "automated" },
  },
  {
    name: "50.IC⑤",
    description: "50.IC⑤",
    displayOrder: 35,
    tags: { createdBy: "automated" },
  },
];